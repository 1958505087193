const Data = {
    menuItems: [
      {
        name: "Home",
        path: "/#home"
      },
      {
        name: "Company",
        path: "/#company",
        offset: "-50"
      },
      {
        name: "Services",
        path: "/#services",
        offset: "-50"
      },
      {
        name: "Partners",
        path: "/#partners",
        offset: "-50"
      },
      {
        name: "Clients",
        path: "/#clients",
        offset: "-50"
      },
      {
        name: "Contact",
        path: "/#contact",
        offset: "-50"
      },
      {
        name: "Blog",
        path: "https://ghost.cyder.com.sg/",
        newTab: true
      },
      {
        name: "Bifrost",
        path: "https://bitfrost.cyder.page",
        newTab: true
      }
    ]
  };
  export default Data;
  